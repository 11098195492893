import { z } from 'zod';

export type User = {
  id: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  isEmployee: boolean;
};

export const UserSchema = z.object({
  id: z.string(),
  emailAddress: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  isEmployee: z.boolean(),
});
export const isUser = (object: unknown): object is User => {
  const user = object as User;
  return !!user && user.id !== undefined && user.isEmployee !== undefined;
};
